<template>
  <form @submit.prevent="onsubmit" class="login">
    <div class="row mb-2" v-if="resetToken === null">
      <va-input
        v-model="email"
        type="email"
        :label="$t('auth.email')"
        :error="!!emailErrors.length"
        :error-messages="emailErrors"
      />
    </div>
    <div v-else>
      <va-input
        v-model="password"
        type="password"
        label="Password"
        :error="!!passwordErrors.length"
        :error-messages="passwordErrors"
      />
      <br />
      <va-input
        v-model="confirmPassword"
        type="password"
        label="Confirm password"
        :error="!!passwordErrors.length"
        :error-messages="passwordErrors"
      />
    </div>

    <div class="row justify--center">
      <va-button type="submit" class="my-0">{{
        $t('auth.reset_password')
      }}</va-button>
    </div>
  </form>
</template>

<script>
import { forgetPassword, resetPassword } from '../../../apollo/api/auth';
export default {
  name: 'recover-password',
  data() {
    return {
      email: '',
      resetToken: null,
      emailErrors: [],
      password: '',
      confirmPassword: '',
      passwordErrors: [],
    };
  },
  created() {
    if (this.$route.query.token) {
      this.resetToken = this.$route.query.token;
    }
  },
  methods: {
    async onsubmit() {
      if (this.resetToken === null) {
        if (!this.email) {
          this.emailErrors = ['Email is required'];
          return;
        }

        try {
          await forgetPassword(this.email);
          this.showToast('Password reset mail sent', {
            position: 'top-right',
            duration: 1200,
            fullWidth: false,
          });
        } catch (e) {
          this.showToast(e, {
            position: 'top-right',
            duration: 1200,
            fullWidth: false,
          });
        }
      } else {
        if (!this.password || !this.confirmPassword) {
          this.passwordErrors = ['Password or confirm password is required'];
          return;
        }
        if (this.password !== this.confirmPassword) {
          this.passwordErrors = ['Passwords do not match'];
          return;
        }

        try {
          await resetPassword(this.resetToken, this.password);
          this.showToast('Password reset successfully', {
            position: 'top-right',
            duration: 1200,
            fullWidth: false,
          });
          this.$router.push({ name: 'login' });
        } catch (e) {
          this.showToast(e, {
            position: 'top-right',
            duration: 1200,
            fullWidth: false,
          });
        }
      }
    },
  },
};
</script>
